import http from "../http-common"
import authHeader from './auth-header';


class FileManagerDataService {

    //Запрос записи
    get() {
        return http.get(`/filelist`,{ headers: authHeader() });
    }



}

export default new  FileManagerDataService()