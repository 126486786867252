<template>
    <div>
        <AddTaskForm
                @updateTasks="get"
        >
        </AddTaskForm>

        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Задачи</span>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn
                        color="primary"
                        depressed
                        @click="addTask"
                >
                    Создать
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-form
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                    <v-text-field
                                            label="Срок выполнения"
                                            placeholder="Начало"
                                            v-model="startTaskDeadlineDate"
                                            v-mask="'##.##.####'"
                                            :rules="dateRules"
                                            ref="startDeadlineDate"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                    <v-text-field
                                            placeholder="Окончание"
                                            v-model="endTaskDeadlineDate"
                                            v-mask="'##.##.####'"
                                            :rules="dateRules"
                                            ref="endDeadlineDate"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                                <v-row>
                                    <v-col style="padding-top: 0; padding-bottom: 15px">
                                        <div class="data-label">Статус</div>
                                        <div v-for="item in status" :key="item.val">
                                            <v-checkbox
                                                    v-model="statusArr"
                                                    :value="item.val"
                                                    :label="`${item.text}`"
                                                    hide-details="true"
                                            ></v-checkbox>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row
                                        align="center"
                                        justify="space-around"
                                >
                                    <v-btn
                                            depressed
                                            @click="resetForm"
                                            class="mb-3"
                                    >
                                        Сбросить
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            depressed
                                            color="primary"
                                            @click="get"
                                            class="mb-3"
                                    >
                                        Применить
                                    </v-btn>
                                </v-row>
                        </v-form>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="computedHeaders"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >


                    <template v-slot:item.name="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditTask', params: { id: item.id }}"
                                :class="{done : item.status == 1}"
                        >
                                {{ item.name }}
                        </router-link>
                            <v-icon
                                :color="getColor(item.status)"
                                size="20"
                                v-if="item.importance == 1"
                            >
                                mdi-fire
                            </v-icon>
                    </template>

                    <template v-slot:item.end_date.date="{ item }">
                        <div :class="{enddeadline: getDeadlineStatus(item.end_date.date, item.status) == true}">
                            {{ formatDeadlineDate (item.end_date.date, item.status ) }}
                        </div>
                    </template>

<!--                    <template v-slot:item.status="{ item }">-->
<!--                        {{ item.status | formatStatusToText }}-->
<!--                    </template>-->

                    <template v-slot:item.status="{ item }">
                        <task-stepper :statusid="item.status" :taskid="item.id"/>
                    </template>

                    <template v-slot:item.order_number="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditContract', params: { id: item.order_id }}">
                                {{ item.order_number }}
                        </router-link>
                    </template>

                    <template v-slot:item.create_employee_name="{ item }">
                        <v-avatar size="30" style="margin-right: 5px">
                            <img :src="`${avatars}${item.create_employee_avatar}`">
                        </v-avatar>
                            {{ item.create_employee_name}}
                    </template>

                    <template v-slot:item.responsible_employee_name="{ item }">
                        <v-avatar size="30" style="margin-right: 5px">
                            <img :src="`${avatars}${item.responsible_employee_avatar}`">
                        </v-avatar>
                        {{ item.responsible_employee_name}}
                    </template>


                    <template v-slot:item.create_date.date="{ item }">
                        {{ item.create_date.date | formatDate}}
                    </template>

                    <template v-slot:item.deletetask="{ item }">
                        <v-icon
                                size="25"
                                @click="destroytask(item)"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import TaskDataService from "@/services/TaskDataService";
    import AddTaskForm from "@/components/AddTaskForm";
    // import EditTaskForm from "@/components/EditTaskForm";
    import TaskStepper from "@/components/elements/TaskStepper";


    export default {
        name: "TasksTable",
        components: {
            TaskStepper,
            AddTaskForm,
            // EditTaskForm
        },
        data () {
            return {
                //Переменные для компонента радактирования
                showdialog: false,
                editableTaskId: null,


                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD',
                },
                status: [
                    { text: 'Ожидает выполнения', val: '0' },
                    { text: 'Завершена', val: '1' },
                ],
                statusArr: [],
                favorite: false,
                userRoles: null,

                startTaskDeadlineDate: '',
                endTaskDeadlineDate: '',

                valid: true,

                maxResults: 0,

                contractNumberString: '', //Строка поиска - номер договора
                customerString: '', //Строка поиска - заказчик
                addressString: '', //Строка поиска - адрес

                loading: true,
                items: [],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
            }
        },
        mounted () {
            // //read Localstorage
            // if (localStorage.contractNumberString) {
            //     this.contractNumberString = localStorage.contractNumberString
            // }
            // if (localStorage.customerString) {
            //     this.customerString = localStorage.customerString
            // }
            // if (localStorage.addressString) {
            //     this.addressString = localStorage.addressString
            // }
            // if (localStorage.startContractDate) {
            //     this.startContractDate = localStorage.startContractDate
            // }
            // if (localStorage.startContractDate) {
            //     this.endContractDate = localStorage.endContractDate
            // }
            if (localStorage.startTaskDeadlineDate) {
                this.startTaskDeadlineDate = localStorage.startTaskDeadlineDate
            }
            if (localStorage.endTaskDeadlineDate) {
                this.endTaskDeadlineDate = localStorage.endTaskDeadlineDate
            }
            if (localStorage.taskstatus) {
                this.statusArr = JSON.parse(localStorage.getItem('taskstatus'))
            }
            // if (localStorage.contractMaxResults) {
            //     this.maxResults = localStorage.contractMaxResults
            // } else
            // {
            //     this.maxResults = 50; //По умолчанию, если нет запичи в сторадже
            // }
            // if (localStorage.favorite) {
            //     this.favorite = JSON.parse(localStorage.favorite)
            // }
            // this.userRoles = this.$store.state.auth.user.roles //Роли зарегистрированного пользователя

            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {
            formatDate: function (date) {
                    return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            // formatStatusToText(status) {
            //     return status == 0 ? 'Ожидает выполнения' : 'Выполнено'
            // }
        },

        computed: {
            computedHeaders () {
                const header =  [
                    {
                        text: 'Название',
                        value: 'name',
                        width: 400
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                        width: 100
                    },
                    {
                        text: 'Дата создания',
                        value: 'create_date.date',
                        width: 150
                    },
                    {
                        text: 'Срок',
                        value: 'end_date.date',
                        width: 150
                    },
                    {
                        text: 'Договор',
                        value: 'order_number',
                        width: 100
                    },
                    {
                        text: 'Постановщик',
                        value: 'create_employee_name',
                        width: 250,
                    },
                    {
                        text: 'Ответственный',
                        value: 'responsible_employee_name',
                    },
                    {
                        text: '',
                        value: 'deletetask',
                        width: 20
                    },

                    ]
                return header
            }
        },


        methods: {
            addTask() {
                this.$store.dispatch('showAddTaskDialog');
            },

            destroytask(task) {
                this.$confirm(`Вы действительно хотите удалить задачу?`, 'Удаление', { color: 'error', yesLabel: 'Удалить', noLabel: 'Отмена' })
                     .then(canDelete => canDelete ? this.deleteTask(task) : null)
                    //.then(isDestroyed => isDestroyed && this.removeTask(task))

            },
            deleteTask(task) {
                TaskDataService.delete(task.id)
                    .then(response => {
                        console.log(response)
                        this.removeTask(task)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            removeTask(task) {
                const index = this.items.indexOf(task)
                this.items.splice(index, 1)
            },

            getColor(status) {
                if (status == 0) return 'red'
                else  return 'grey'
            },

            //Отображаем диалог редактирования
            //В параметры передаем ID редактируемого объекта
            showEditDialog(id) {
                this.showdialog = true
                this.editableTaskId = id //Код редактируемой задачи для передачи компоненту
            },

            formatDeadlineDate(date, status) {
                //console.log(new Date(date), new Date())
                if (date)
                    if ((new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) && ( status == 0) ) {  //Для правильного сравнения дат обнуляем часы
                        let datediff = Math.floor((new Date(date) - new Date()) / (60 * 60 * 24 * 1000)) + 1  //Прибавляем +1( один день) чтобы день дедлайна был включительно
                        return datediff + " дн."
                    } else {
                        return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
                    }
                else
                    return 'без срока'
            },
            //True - задача просрочена
            getDeadlineStatus(date, status) {
                if (date)
                    if ((new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) && ( status == 0) ) {
                        return true
                    } else {
                        return false
                    }
                else
                    return 'без срока'
            },

            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },

            resetForm: function () {
                this.startTaskDeadlineDate = ''
                this.endTaskDeadlineDate = ''
                this.statusArr = []

            },
            get: function () {
                //Save Localstorage
                // localStorage.contractNumberString = this.contractNumberString
                // localStorage.customerString = this.customerString
                // localStorage.addressString = this.addressString
                // localStorage.startContractDate = this.startContractDate
                // localStorage.endContractDate = this.endContractDate
                 localStorage.startTaskDeadlineDate = this.startTaskDeadlineDate
                 localStorage.endTaskDeadlineDate = this.endTaskDeadlineDate
                 localStorage.setItem('taskstatus', JSON.stringify(this.statusArr))
                // localStorage.setItem('favorite', JSON.stringify(this.favorite))

                this.loading = true

                let params = {
                    startTaskDeadlineDate: this.formatDateToIso(this.startTaskDeadlineDate),
                    endTaskDeadlineDate: this.formatDateToIso(this.endTaskDeadlineDate),
                    status: this.statusArr,
                }

                TaskDataService.find(this.$store.state.auth.user.userid, params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            // formatDate (date) {
            //     if (!date) return null
            //
            //     const [year, month, day] = date.split('-')
            //     return `${day}.${month}.${year}`
            // },

        }
    }
</script>

<style scoped>
    .reportwarning {
        /*background-color: #fbaeae;*/
        /*padding: 2px 5px 2px 5px;*/
        /*border-radius: 3px;*/
        color: red;
    }

        /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }

    .done {
        text-decoration: line-through  !important;
        color: #686868 !important;
    }

</style>