import http from "../http-common"
import authHeader from './auth-header';

class EmployeeDataService {

    //Запрос (поиск) записей
    find(searchstring = '', works = false) {
        return http.get(`/employees?searchstring=${searchstring}&works=${works}`, { headers: authHeader() });
    }

    //Создание записи
    create(data) {
        return http.post("/employees", data, { headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/employees/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`employees/${id}`, data, { headers: authHeader() })
    }

    //Запрос дней рождения за 3 дня
    getbirthday() {
        return http.get(`/employeesgetbirthday`,{ headers: authHeader() });
    }

    //Запрос дней рождения за период
    getbirthdayPeriod(startDate, endDate) {
        return http.get(`/employeesgetbirthdayperiod?startDate=${startDate}&endDate=${endDate}`,{ headers: authHeader() });
    }

    //Изменение пароля
    changePassword(employeeid, oldpassword, newpassword) {
        return http.post(`/employees/changepassword?employeeid=${employeeid}&oldpassword=${oldpassword}&newpassword=${newpassword}`, null, { headers: authHeader() })
    }

    //Изменения аватара
    // file - загружаемый файл
    // params - параметры, передаваемые в запросе
    // onUploadProgress - событие прогресса при загрузке файла
    changeAvatar (formData, params) {

        return http.post('/employees/change_avatar', formData, {
            headers: Object.assign(authHeader(), {"Content-Type": "multipart/form-data"}), //Объединяем 2 заголовка
            params
        })
    }


}

export default new EmployeeDataService()