<template>
    <v-dialog
            v-model="dialog"
            :max-width="options.width"
            @keydown.esc="cancel"
    >
        <v-toolbar dark :color="options.color" dense v-if="title">
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    @click="cancel"
                    color="white"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card tile>
            <v-card-text class="pt-5" v-show="!!message">
               {{ message }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        depressed
                        @click="cancel"
                        name="reject"
                >
                    {{ options.noLabel }}
                </v-btn>
                <v-btn
                        :color="options.color"
                        depressed
                        @click="agree"
                        name="confirm"
                >
                    {{ options.yesLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            message: String,
            title: String,
            params: Object
        },

        data() {
            return {
                dialog: false,
                options: {
                    color: 'primary',
                    width: 350,
                    yesLabel: 'Yes',
                    noLabel: 'No'
                }
            }
        },
        methods: {
            agree() {
                this.dialog = false
                this.$emit('close', true)
            },

            cancel() {
                this.dialog = false
                this.$emit('close', false)
            },

            open() {
                this.dialog = true
            }
        },
        created() {
            Object.assign(this.options, this.params)
        }
    }
</script>