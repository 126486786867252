<template>
  <div>

      <!--Сообщение об успешном копировании данных-->
      <v-snackbar
              v-model="snackbarCopy"
              color="green darken-3"
              top
              :timeout="1000"
      >
          Скопировано!
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarCopy = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

      <!--Сообщение об ошибке сохранения в БД-->
      <v-alert
              type="error"
              dismissible
              v-model="alertError"
              transition="scale-transition"
      >
          Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
      </v-alert>

      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

        <v-row>
            <v-col
                    cols="auto"
            >
                <h1 class="data-name" v-show="!orderInPlace">
                Договор № {{ order.contractnumber }} от {{ formatOrderDate(order.orderdata.date) }}
                <v-icon
                        right
                        size="29"
                        @click="addToFavorite"
                        v-show="(order.favoriteforemployer == NULL) ||(order.favoriteforemployer != $store.state.auth.user.userid) "
                >
                    mdi-star-outline
                </v-icon>
                <v-icon
                        size="29"
                        @click="deleteFromFavorite"
                        color="#f4942d"
                        v-show="(order.favoriteforemployer != NULL) &&  (order.favoriteforemployer == $store.state.auth.user.userid)"
                >
                    mdi-star
                </v-icon>
                <v-icon
                        @click="editOrder"
                        right
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        @click="copy(order.contractnumber + ` от ` + formatOrderDate(order.orderdata.date))"
                        right
                >
                    mdi-content-copy
                </v-icon>
            </h1>
                <div v-show="orderInPlace">
                    <v-form
                            ref="order"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                label="Номер договора"
                                v-model="editedItem.contractnumber"
                                :rules="externalRules.requiredRules"
                                ref="contractnumber"
                        ></v-text-field>

                        <vc-date-picker v-model="editedItem.orderdata" :model-config="rusModelConfig">
                            <template v-slot="{ inputValue, togglePopover }">
                                <v-text-field
                                        label="Дата"
                                        :value="inputValue"
                                        :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                        @click="togglePopover()"
                                        v-mask="'##.##.####'"
                                        v-model="editedItem.orderdata"
                                        ref="orderdata"
                                />
                            </template>
                        </vc-date-picker>

                    </v-form>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeOrderInPlace"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveOrderInPlace"
                    >
                        Сохранить
                    </v-btn>
                </div>
            </v-col>

            <v-col
                    cols="auto"
            >
                <v-btn-toggle
                        v-model="order.orderstatus"
                        class="ml-5"
                        mandatory
                        @change="updateOrder"
                >
                    <v-btn value="4"
                           width="150px"
                           max-height="40px"
                           active-class="isNew"
                    >
                        Новый
                    </v-btn>
                    <v-btn value="0"
                           width="150px"
                           max-height="40px"
                           active-class="isWork"
                    >
                        В работе
                    </v-btn>

                    <v-btn value="1"
                           width="150px"
                           max-height="40px"
                           active-class="isCompleted"
                    >
                        Выполнен
                    </v-btn>

                    <v-btn value="3"
                           width="150px"
                           max-height="40px"
                           active-class="isRefuse"
                    >
                        Отказ
                    </v-btn>
                </v-btn-toggle>
            </v-col>


            <v-spacer></v-spacer>
            <v-col
                    cols="auto"
                    v-show="VUE_APP_TENANT !== 'GEOREESTR'"
            >
                <v-btn
                        depressed
                        color="primary"
                        @click="openProject"
                >
                    <v-icon left>
                        mdi-folder-multiple-outline
                    </v-icon>
                    Проект
                </v-btn>
            </v-col>
        </v-row>
      <v-tabs
              v-model="tab"
      >
          <v-tab>Основное</v-tab>
          <v-tab>
              Задачи
              <v-badge
                      color="green"
                      :content="tasksInWorking"
                      v-show="tasksInWorking > 0"
                      style="padding-left: 5px"
              >
              </v-badge>
          </v-tab>
          <v-tab
                  v-if="$can('ACCOUNTING_READ', 'all')"
          >
              Бухгалтерия
          </v-tab>
          <v-tab v-show="VUE_APP_TENANT !== 'GEOREESTR'">
              Исполнители
          </v-tab>
          <v-tab
                  v-if="$can('ECONOMY_READ', 'all')"
                  v-show="VUE_APP_TENANT !== 'GEOREESTR'"
          >
              Экономика
          </v-tab>
          <v-tab v-show="VUE_APP_TENANT !== 'GEOREESTR'">
              Корреспонденция
          </v-tab>
          <v-tab
                  v-if="$can('OFFERS_LIST_READ', 'all')"
                  v-show="VUE_APP_TENANT !== 'GEOREESTR'"
          >
              Предложения
          </v-tab>
          <v-tab v-show="VUE_APP_TENANT !== 'GEOREESTR'">
              Личный кабинет
          </v-tab>
      </v-tabs>

      <v-tabs-items
              v-model="tab"
      >
        <!--Вкладка ОСНОВНОЕ  -->
        <v-tab-item>
            <v-row>
                <v-col>
                    <v-card
                            outlined
                            class="mb-4"
                    >
                        <v-card-title>Основное</v-card-title>
                        <v-card-text>

                            <!--Организация-->
                            <v-row class="mx-0">
                                <div class="data-row">
                                    <div class="data-label">
                                        Организация
                                    </div>
                                    <div class="data-text" v-show="!orderorganizationInPlace">
                                        <span>{{ order.orderorganization.companyname }}</span>
                                        <v-icon
                                                @click="editOrderorganization"
                                                right
                                                small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                                @click="copy(order.orderorganization.companyname)"
                                                right
                                                small
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                    </div>
                                    <div v-show="orderorganizationInPlace">
                                        <v-row>
                                            <v-col>
                                                <v-select
                                                        v-model="editedItem"
                                                        :items="orderorganizationItems"
                                                        item-text="companyname"
                                                        item-value="companyid"
                                                        return-object
                                                        :rules="requiredRules"
                                                        required
                                                ></v-select>
                                                <v-btn
                                                        color="blue darken-1"
                                                        text
                                                        @click="closeOrderorganization"
                                                >
                                                    Отмена
                                                </v-btn>
                                                <v-btn
                                                        color="blue darken-1"
                                                        text
                                                        @click="saveOrderorganization"
                                                >
                                                    Сохранить
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>

                            </v-row>


                            <!--Заказчик-->
                            <v-row class="mx-0">
                                <div class="data-row">
                                    <div class="data-label">
                                        Заказчик
                                    </div>
                                    <div class="data-text" v-show="!customerInPlace">
                                        <span style="color: rgb(13, 71, 161); font-weight: bold;"> {{ order.customerkod.fullname }} </span>
                                        <v-icon
                                                @click="clearCustomer"
                                                right
                                                small
                                        >
                                            mdi-close-circle-outline
                                        </v-icon>
                                        <v-icon
                                                @click="goToCustomer"
                                                right
                                                small
                                        >
                                            mdi-arrow-top-right
                                        </v-icon>
                                        <v-icon
                                                @click="copy(order.customerkod.fullname)"
                                                right
                                                small
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                    </div>
                                    <div v-show="customerInPlace">
                                        <v-autocomplete
                                                placeholder="Введите не менее 3-х символов для поиска"
                                                clearable
                                                v-model="customer"
                                                :items="customerItems"
                                                :search-input.sync="searchCustomer"
                                                return-object
                                                item-text="fullname"
                                                ref="customer"
                                        ></v-autocomplete>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeCustomerInPlace"
                                        >
                                            Отмена
                                        </v-btn>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="saveCustomerInPlace"
                                        >
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-row>

                            <!--Объект недвижимости-->
                            <v-row class="mx-0">
                                <div class="data-row">
                                    <div class="data-label">
                                        Объект
                                    </div>
                                    <div class="data-text" v-show="!estateInPlace">
                                        <span style="color: rgb(13, 71, 161); font-weight: bold;"> {{ order.estatecod.fulladdress }} </span>
                                        <v-icon
                                                @click="clearEstate"
                                                right
                                                small
                                        >
                                            mdi-close-circle-outline
                                        </v-icon>
                                        <v-icon
                                                @click="goToEstate"
                                                right
                                                small
                                        >
                                            mdi-arrow-top-right
                                        </v-icon>
                                        <v-icon
                                                @click="copy(order.estatecod.fulladdress)"
                                                right
                                                small
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                    </div>
                                    <div v-show="estateInPlace">
                                        <v-autocomplete
                                                placeholder="Введите не менее 5-х символов для поиска"
                                                clearable
                                                v-model="estate"
                                                :items="estateItems"
                                                :search-input.sync="searchEstate"
                                                return-object
                                                item-text="fulladdress"
                                                ref="estate"
                                        ></v-autocomplete>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeEstateInPlace"
                                        >
                                            Отмена
                                        </v-btn>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="saveEstateInPlace"
                                        >
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>

                            </v-row>

                            <!--Срок договора-->
                            <v-row class="mx-0">
                                <div class="data-row">
                                    <div class="data-label">
                                        Срок
                                    </div>
                                    <div class="data-text" v-show="!orderdeadlineInPlace">
                                        <span>{{ formatOrderDate(order.orderdeadline.date) }} <span style="background-color: #d0d0d7; padding: 3px 4px; font-size: 11px; border-radius: 3px;">{{ deadlineDaysCalc }} дн.</span></span>
                                        <v-icon
                                                @click="editOrderdeadline"
                                                right
                                                small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </div>
                                    <div v-show="orderdeadlineInPlace">
                                        <v-form
                                                ref="order"
                                                v-model="valid"
                                                lazy-validation
                                        >
                                            <vc-date-picker v-model="editedItem.orderdeadline" :model-config="modelConfig">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <v-text-field
                                                            required
                                                            v-on="inputEvents"
                                                            :value="inputValue"
                                                            readonly
                                                            :rules="requiredRules"
                                                            ref="orderdeadline"
                                                    ></v-text-field>
                                                </template>
                                            </vc-date-picker>
                                        </v-form>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeOrderdeadline"
                                        >
                                            Отмена
                                        </v-btn>
                                        <v-btn
                                                :disabled="!valid"
                                                color="blue darken-1"
                                                text
                                                @click="saveOrderdeadline"
                                        >
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-row>

                            <!--                      <v-row class="mx-0">-->
                            <!--                          <div class="data-row">-->
                            <!--                              <div class="data-label">-->
                            <!--                                  Выезд на объект-->
                            <!--                              </div>-->
                            <!--                              <div class="data-text" v-show="!departureInPlace">-->
                            <!--                                  <span>{{ formatOrderDate(order.orderdeadline.date) }} <span style="background-color: #d0d0d7; padding: 3px 4px; font-size: 11px">7 дней</span></span>-->
                            <!--                                  <v-icon-->
                            <!--                                          @click="editDepartureDate"-->
                            <!--                                          right-->
                            <!--                                          small-->
                            <!--                                  >-->
                            <!--                                      mdi-pencil-->
                            <!--                                  </v-icon>-->
                            <!--                              </div>-->
                            <!--                              <div v-show="departureInPlace">-->
                            <!--                                  <v-form-->
                            <!--                                          ref="order"-->
                            <!--                                          v-model="valid"-->
                            <!--                                          lazy-validation-->
                            <!--                                  >-->
                            <!--                                      <vc-date-picker v-model="editedItem.datedeparture">-->
                            <!--                                          <template v-slot="{ inputValue, inputEvents }">-->
                            <!--                                              <v-text-field-->
                            <!--                                                      label="Дата"-->
                            <!--                                                      v-mask="'##.##.####'"-->
                            <!--                                                      required-->
                            <!--                                                      v-on="inputEvents"-->
                            <!--                                                      :value="inputValue"-->
                            <!--                                                      readonly-->
                            <!--                                                      :rules="requiredRules"-->
                            <!--                                                      ref="datedeparture"-->
                            <!--                                              ></v-text-field>-->
                            <!--                                          </template>-->
                            <!--                                      </vc-date-picker>-->
                            <!--                                  </v-form>-->
                            <!--                                  <v-btn-->
                            <!--                                          color="blue darken-1"-->
                            <!--                                          text-->
                            <!--                                          @click="closeDepartureDateInPlace"-->
                            <!--                                  >-->
                            <!--                                      Отмена-->
                            <!--                                  </v-btn>-->
                            <!--                                  <v-btn-->
                            <!--                                          :disabled="!valid"-->
                            <!--                                          color="blue darken-1"-->
                            <!--                                          text-->
                            <!--                                          @click="saveDepartureDateInPlace"-->
                            <!--                                  >-->
                            <!--                                      Сохранить-->
                            <!--                                  </v-btn>-->
                            <!--                              </div>-->
                            <!--                          </div>-->
                            <!--                      </v-row>-->

                            <v-row class="mx-0">
                                <div class="data-row">
                                    <div class="data-label">
                                        Выезд на объект
                                    </div>
                                    <div class="data-text" v-show="!departureInPlace">
                                        <span v-if="order.departureflag">{{ formatOrderDate(order.datedeparture.date) }}</span>
                                        <span v-else class="placeholder">Выберите дату выезда</span>
                                        <span v-show="order.departureflag" style="margin-left: 10px">
                                      <v-icon
                                              size="16"
                                              style="visibility: visible; color: #716f71"
                                      >
                                          mdi-clock-outline
                                      </v-icon>
                                      {{ order.timedeparture }}
                                  </span>
                                        <v-icon
                                                v-show="!order.departureflag"
                                                @click="editDatedeparture"
                                                right
                                                small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                                v-show="order.departureflag"
                                                @click="clearDatedeparture"
                                                right
                                                small
                                        >
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </div>
                                    <div v-show="departureInPlace">
                                        <v-form
                                                ref="departure"
                                                v-model="valid"
                                                lazy-validation
                                        >
                                            <v-row>
                                                <v-col>
                                                    <vc-date-picker v-model="editedItem.datedeparture" :model-config="modelConfig">
                                                        <template v-slot="{ inputValue, inputEvents }">
                                                            <v-text-field
                                                                    label="Дата выезда"
                                                                    v-mask="'##.##.####'"
                                                                    required
                                                                    v-on="inputEvents"
                                                                    :value="inputValue"
                                                                    readonly
                                                                    :rules="requiredRules"
                                                                    ref="datedeparture"
                                                            ></v-text-field>
                                                        </template>
                                                    </vc-date-picker>
                                                </v-col>
                                                <v-col>
                                                    <v-select
                                                            v-model="editedItem.timeDepartureSelect"
                                                            :items="timeDeparture"
                                                            label="Время выезда"
                                                            item-text="label"
                                                            item-value="value"
                                                            return-object
                                                            :rules="requiredRules"
                                                            required
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeDepartureDateInPlace"
                                        >
                                            Отмена
                                        </v-btn>
                                        <v-btn
                                                :disabled="!valid"
                                                color="blue darken-1"
                                                text
                                                @click="saveDepartureDateInPlace"
                                        >
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>

                            </v-row>


                            <v-row class="mx-0">
                                <div class="data-row">
                                    <div class="data-label">
                                        Краткое описание предмета договора
                                    </div>
                                    <div
                                            class="data-text"
                                            v-show="!ordertargetInPlace"
                                    >
                                        <span v-if="order.ordertarget"> {{ order.ordertarget }} </span>
                                        <span class="placeholder" v-else>Введите текст</span>
                                        <v-icon
                                                @click="editOrdertargetInPlace"
                                                right
                                                small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                                v-show="order.ordertarget"
                                                @click="copy(order.ordertarget)"
                                                right
                                                small
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                    </div>
                                    <div v-show="ordertargetInPlace">
                                        <v-textarea
                                                v-model="editedItem.ordertarget"
                                                ref="ordertarget"
                                        ></v-textarea>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeOrdertargetInPlace"
                                        >
                                            Отмена
                                        </v-btn>
                                        <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="saveOrdertargetInPlace"
                                        >
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col>
                    <v-card
                            outlined
                            class="mb-4"
                    >
                        <v-card-title>
                            Участники
                            <v-spacer></v-spacer>
                            <v-btn
                                    icon
                                    @click="changeMemberShowBlock"
                            >
                                <v-icon>{{ showMemberBlock ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-expand-transition>
                            <div v-show="showMemberBlock">
                                <v-card-text>
                                    <!-- Передаем в компонент ID менеджера заказа и создателя-->
                                    <Members :managerid="order.managerid" :creatorid="order.creatorid" :estate="order.estatecod.fulladdress" :contractnumber="order.contractnumber" :orderdata="order.orderdata"/>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                    <v-card
                            outlined
                            class="mb-4"
                    >
                        <v-card-title>Комментарии</v-card-title>
                        <v-card-text>
                            <!--                            Передаем во входной параметр весь объект заказа-->
                            <order-messages :order="order"/>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
        </v-tab-item>

          <!--Вкладка ЗАДАЧИ  -->
          <v-tab-item>
              <v-row>
                  <v-col>
                      <v-card
                              outlined
                              class="mb-4"
                      >
                          <v-card-text>
                              <TasksTable/>
                          </v-card-text>
                      </v-card>
                  </v-col>
              </v-row>
          </v-tab-item>


        <!--Вкладка БУХГАЛТЕРИЯ  -->
<!--          :eager="true"-->
        <v-tab-item v-if="$can('ACCOUNTING_READ', 'all')">
        <v-row>
            <v-col>
                <v-card
                        outlined
                        class="mb-4"
                >
                    <v-card-title>Смета</v-card-title>
                    <v-card-text>
                        <NomenclatureTable :company="order.orderorganization"></NomenclatureTable>
                    </v-card-text>
                </v-card>

                <!--Акты сдачи работ-->
                <ActsTable :company="order.orderorganization" v-if="VUE_APP_TENANT !== 'GEOREESTR'"></ActsTable>

                <!--Файлы договора, актов и т.п.-->
                <v-card
                        outlined
                        class="mb-4"
                        v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                >
                    <v-card-title>Файлы</v-card-title>
                    <v-card-text>
                        <ContractFileTable></ContractFileTable>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>

                <!--Счета-->
                <AccountTable :company="order.orderorganization"/>

                <!--Касса (Поступление)-->
                <CreditTable :company="order.orderorganization" :order="order" v-if="VUE_APP_TENANT !== 'GEOREESTR'"/>

                <!--Расходы (Документы - себестоимость)-->
                <DocumentsTable v-if="VUE_APP_TENANT !== 'GEOREESTR'"/>

                <!--Прочая коммерческая информация -->
                <v-card
                        outlined
                        class="mb-4"
                        v-if="VUE_APP_TENANT !== 'GEOREESTR'"
                >
                    <v-card-title>Прочая коммерческая информация</v-card-title>
                    <v-card-text>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div
                                        class="data-text"
                                        v-show="!orderExpenseInPlace"
                                >
                                    <span v-if="order.orderexpense"> {{ order.orderexpense }} </span>
                                    <span class="placeholder" v-else>Введите текст</span>
                                    <v-icon
                                            @click="editOrderExpenseInPlace"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                            v-show="order.orderexpense"
                                            @click="copy(order.orderexpense)"
                                            right
                                            small
                                    >
                                        mdi-content-copy
                                    </v-icon>
                                </div>
                                <div v-show="orderExpenseInPlace">
                                    <v-textarea
                                            v-model="editedItem.orderexpense"
                                            ref="orderexpense"
                                    ></v-textarea>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeOrderExpenseInPlace"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveOrderExpenseInPlace"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-tab-item>

        <!--Вкладка ИСПОЛНИТЕЛИ  -->
        <v-tab-item>
<!--            pd 150 px чтобы календарь открывался, иначе перекрывается верхними слоями-->
            <v-row style="padding-bottom: 150px">
              <v-col>
                  <!--Выполненные работы-->
                  <WorksTable :company="order.orderorganization"/>


                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Исполнители</v-card-title>
                      <v-card-text>
                          <ServiceviewEmployeer/>
                      </v-card-text>
                  </v-card>
              </v-col>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                          v-if="$can('SALARY_READ', 'all')"
                  >
                      <v-card-title>ЗП</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Начисление в ЗП
                                  </div>
                                  <div class="data-text" v-show="!salaryInPlace">
                                      <span v-if="order.ordersalaryflag">Закрыто в ЗП, дата начисления: {{ formatOrderDate(order.ordersalarydate.date) }}</span>
                                      <span v-else class="placeholder">Выберите дату начисления</span>
                                      <v-icon
                                              @click="editSalaryDate"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                  </div>
                                  <div v-show="salaryInPlace">
                                      <v-form
                                              ref="departure"
                                              v-model="valid"
                                              lazy-validation
                                      >
                                          <v-row>
                                              <v-col>
                                                  <vc-date-picker v-model="editedItem.salarydate" :model-config="modelConfig">
                                                      <template v-slot="{ inputValue, inputEvents }">
                                                          <v-text-field
                                                                  label="Дата начисления"
                                                                  v-mask="'##.##.####'"
                                                                  required
                                                                  v-on="inputEvents"
                                                                  :value="inputValue"
                                                                  readonly
                                                                  :rules="requiredRules"
                                                                  ref="salarydate"
                                                          ></v-text-field>
                                                      </template>
                                                  </vc-date-picker>
                                              </v-col>
                                          </v-row>
                                      </v-form>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeSalaryDateInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              :disabled="!valid"
                                              color="blue darken-1"
                                              text
                                              @click="saveSalaryDateInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                                  <div
                                          style="margin-top: 20px"
                                  >
                                      <!--Таблица начисления исполнителям-->
                                      <accrual-by-order-table/>
                                  </div>
                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
      </v-tab-item>
          <!--Вкладка ЭКОНОМИКА -->
          <v-tab-item v-if="$can('ECONOMY_READ', 'all')">
              <v-row>
                  <v-col>
                      <v-card
                              outlined
                              class="mb-4"
                      >
                          <v-card-title>Показатели</v-card-title>
                          <v-card-text>
                              <div>ДОХОД</div>
                              <div class="numbers" style="background-color: #7ca9ff; display: inline-block; padding: 10px;">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getOrderSum) }}</div>
                              <div style="margin-top: 10px; ">РАСХОДЫ</div>
                              <div class="numbers" style="background-color: #f7c590; display: inline-block; padding: 10px;">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getExpensesSum + getWageSum) }}</div>
                              <div style="margin-top: 10px">ПРИБЫЛЬ</div>
                              <div class="numbers" style="display: inline-block; padding: 10px;">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getProfit) }}</div>
                              <div style="margin-top: 10px">РЕНТАБЕЛЬНОСТЬ</div>
                              <div class="numbers" style="display: inline-block; padding: 10px;">{{ getProfitability }}%</div>
                          </v-card-text>

<!--                          <iframe-->
<!--                                  src="https://docs.google.com/document/d/1o6wFUsLxCEC_yzDwDVc02y35_YyDYCFQkN9FQ0OAWRI/edit#heading=h.30j0zll"-->
<!--                                    width="100%"-->
<!--                                  height="2000px"-->
<!--                          ></iframe>-->




                      </v-card>
                  </v-col>
              </v-row>
          </v-tab-item>
          <!--Вкладка КОРРЕСПОНДЕНЦИЯ -->
          <v-tab-item>
              <v-row>
                  <v-col>
                      <v-card
                              outlined
                              class="mb-4"
                      >
<!--                          <v-card-title>Корреспонденция</v-card-title>-->
                          <v-card-text>
                            <correspondence-table/>
                          </v-card-text>
                      </v-card>
                  </v-col>
              </v-row>
          </v-tab-item>
          <!--Вкладка ПРЕДЛОЖЕНИЯ -->
          <v-tab-item v-if="$can('OFFERS_LIST_READ', 'all')">
              <v-row>
                  <v-col>
                      <v-card
                              outlined
                              class="mb-4"
                      >
                          <v-card-title>Предложения</v-card-title>
                          <v-card-text>

                          </v-card-text>
                      </v-card>
                  </v-col>
              </v-row>
          </v-tab-item>
          <!--Вкладка ЛИЧНЫЙ КАБИНЕТ-->
          <v-tab-item>
              <v-row>
                  <v-col>
                      <v-card
                              outlined
                              class="mb-4"
                      >
                          <v-card-title>Личный кабинет</v-card-title>
                          <v-card-text>
                              <v-row class="mx-0">
                                  <div class="data-row">
                                      <div class="data-label">
                                          Доступ к файлам ЛК
                                      </div>
                                      <div class="data-text" v-show="!allowDownloadInPlace">
                                          <span>{{ getAllowDownload }}</span>
                                          <v-icon
                                                  @click="editAllowDownload"
                                                  right
                                                  small
                                          >
                                              mdi-pencil
                                          </v-icon>
                                      </div>
                                      <div v-show="allowDownloadInPlace">
                                          <v-row>
                                              <v-col>
                                                  <v-select
                                                          v-model="editedItem"
                                                          :items="lkAllowDownload"
                                                          item-text="label"
                                                          item-value="value"
                                                          return-object
                                                          :rules="requiredRules"
                                                          required
                                                  ></v-select>
                                                  <v-btn
                                                          color="blue darken-1"
                                                          text
                                                          @click="closeAllowDownload"
                                                  >
                                                      Отмена
                                                  </v-btn>
                                                  <v-btn
                                                          color="blue darken-1"
                                                          text
                                                          @click="saveAllowDownload"
                                                  >
                                                      Сохранить
                                                  </v-btn>
                                              </v-col>
                                          </v-row>
                                      </div>

<!--                                      Компонент отображения загруженных файлов-->
                                      <LKFileTable class="mt-4"></LKFileTable>


                                  </div>
                              </v-row>


                              <!--                      <v-row>-->
                              <!--                          <v-col>-->
                              <!--                              <v-select-->
                              <!--                                      v-model="lkSelect"-->
                              <!--                                      :items="lkAllowDownload"-->
                              <!--                                      item-text="label"-->
                              <!--                                      item-value="value"-->
                              <!--                                      label="Доступ к файлам ЛК"-->
                              <!--                                      return-object-->
                              <!--                                      :rules="requiredRules"-->
                              <!--                                      required-->
                              <!--                              ></v-select>-->
                              <!--                          </v-col>-->
                              <!--                      </v-row>-->
                          </v-card-text>
                      </v-card>
                  </v-col>
              </v-row>
          </v-tab-item>
      </v-tabs-items>








  </div>
</template>
<script>
    import OrderDataService from "@/services/OrderDataService";
    import CustomerDataService from "@/services/CustomerDataService"
    import { fileserver } from "../http-common"
    import RealestateDataService from "@/services/RealestateDataService";
    import CompanyDataService from "@/services/CompanyDataService";
    import OrderMessages from "@/components/OrderMessages";
    import LKFileTable from "@/components/LKFileTable";
    import NomenclatureTable from "@/components/NomenclatureTable";
    import AccountTable from "@/components/AccountTable";
    import CreditTable from "@/components/CreditTable";
    import ServiceviewEmployeer from "@/components/ServiceviewEmployeer";
    import ActsTable from "@/components/ActsTable";
    import ContractFileTable from "@/components/ContractFileTable";
    import Members from "@/components/Members";
    import DocumentsTable from "@/components/DocumentsTable";
    import AccrualByOrderTable from "@/components/AccrualByOrderTable";
    import CorrespondenceTable from "@/components/Order/CorrespondenceTable";
    import * as ExternalRules from "@/rules/ExternalRules";
    import TasksTable from "@/components/Order/TasksTable";
    import TaskDataService from "@/services/TaskDataService";
    import WorksTable from  "@/components/WorksTable";


    export default {
        name: 'EditContract',
        components: {
            WorksTable,
            TasksTable,
            CorrespondenceTable,
            DocumentsTable,
            ServiceviewEmployeer,
            NomenclatureTable, LKFileTable, OrderMessages, AccountTable, CreditTable, ActsTable, ContractFileTable, Members, AccrualByOrderTable},
        data() {
            return {
                VUE_APP_TENANT: process.env.VUE_APP_TENANT,
                showMemberBlock: true,
                externalRules: ExternalRules,
                tasksInWorking: 0, //Количество задач в работе

                tab: null,
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD 00:00:00.000000',
                },

                rusModelConfig: {
                    type: 'string',
                    // mask: 'YYYY-MM-DD',
                    mask: 'DD.MM.YYYY',
                },
                snackbarCopy: false,
                snackbarSave: false,
                alertError: false,
                order: {},//Объект текущего заказа
                orderStatus: '2', //Статус заказа


                lkAllowDownload: [
                    { label: 'Без оплаты', value: '0' },
                    { label: 'Оплата 30% и более', value: '30' },
                    { label: 'Оплата 50% и более', value: '50' },
                    { label: 'Оплата 100%', value: '100' }
                ],

                orderInPlace: false,
                customerInPlace: false,
                estateInPlace: false,
                ordertargetInPlace: false,
                orderdeadlineInPlace: false,
                departureInPlace: false,
                orderorganizationInPlace: false,
                allowDownloadInPlace: false,
                orderExpenseInPlace: false,
                salaryInPlace: false,

                timeDeparture: [
                    { label: '8:00', value: '8:00' },
                    { label: '9:00', value: '9:00' },
                    { label: '10:00', value: '10:00' },
                    { label: '11:00', value: '11:00' },
                    { label: '12:00', value: '12:00' },
                    { label: '13:00', value: '13:00' },
                    { label: '14:00', value: '14:00' },
                    { label: '15:00', value: '15:00' },
                    { label: '16:00', value: '16:00' },
                    { label: '17:00', value: '17:00' },
                    { label: '18:00', value: '18:00' },
                    { label: '19:00', value: '19:00' }
                ],

                //Customer search
                customer: {},
                customerItems: [],
                searchCustomer: null,
                customerName: null,

                //Estate search
                estate: {},
                estateItems: [],
                searchEstate: null,
                estateAddress: null,

                valid: true,

                editedItem: {}, //В этой переменной сохраняем объект редактирования

                orderorganizationItems: {}, //Список организаций для Select

                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
            }
        },

        methods: {
            getOrder(id) {
               OrderDataService.get(id)
                   .then(response => {
                       this.order = response.data
                   })
                   .catch(error => {
                       if (error.response && error.response.status === 404) {
                           this.$router.push({ name: 'NotFound' });// Перенаправление на страницу 404
                       } else {
                           console.log(error); // Обработка других ошибок
                       }
                   })
            },
            //Количество задач в работе
            getTaskInWorking() {
                TaskDataService.getTaskInWork(this.$store.state.auth.user.userid, this.$route.params.id)
                    .then(response => {
                        this.tasksInWorking = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            updateOrder() {
                OrderDataService.update(this.order.orderid, this.order)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },


            //Редактирование на лету
            editOrder() {
                this.editedItem.contractnumber = this.order.contractnumber
                this.editedItem.orderdata = this.order.orderdata.date.split(' ')[0].split("-").reverse().join(".") //Преобразуем ISO дату в дату rus формата DD.MM.YYYY
                this.orderInPlace = true
                this.$nextTick(() => {
                    this.$refs.contractnumber.focus();
                })
            },
            saveOrderInPlace () {
                    this.order.contractnumber = this.editedItem.contractnumber
                    this.order.orderdata.date = this.editedItem.orderdata.split(".").reverse().join("-") //Преобразуем в дату ISO формата
                    this.closeOrderInPlace()
                    this.updateOrder()
            },
            closeOrderInPlace () {
                this.orderInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            goToCustomer() {
                this.$router.push({name: 'EditCustomer', params: { id: this.order.customerkod.customerid }})
            },
            clearCustomer() {
                this.customerInPlace = true
            },
            closeCustomerInPlace() {
                this.customerInPlace = false
                this.customer = {}
            },
            saveCustomerInPlace() {
                this.order.customerkod =  this.customer
                this.closeCustomerInPlace()
                this.updateOrder()
            },


            goToEstate() {
                this.$router.push({name: 'EditRealestates', params: { id: this.order.estatecod.estateid }})
            },
            clearEstate() {
                this.estateInPlace = true
            },
            closeEstateInPlace() {
                this.estateInPlace = false
                this.estate = {}
            },
            saveEstateInPlace() {
                this.order.estatecod =  this.estate
                this.closeEstateInPlace()
                this.updateOrder()
            },


            editOrdertargetInPlace() {
                this.editedItem.ordertarget = this.order.ordertarget
                this.ordertargetInPlace = true
                this.$nextTick(() => {
                    this.$refs.ordertarget.focus();
                })
            },
            closeOrdertargetInPlace() {
                this.ordertargetInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveOrdertargetInPlace() {
                this.order.ordertarget = this.editedItem.ordertarget
                this.closeOrdertargetInPlace()
                this.updateOrder()
            },


            editOrderExpenseInPlace() {
                this.editedItem.orderexpense = this.order.orderexpense
                this.orderExpenseInPlace = true
                this.$nextTick(() => {
                    this.$refs.orderexpense.focus();
                })
            },
            closeOrderExpenseInPlace() {
                this.orderExpenseInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveOrderExpenseInPlace() {
                this.order.orderexpense = this.editedItem.orderexpense
                this.closeOrderExpenseInPlace()
                this.updateOrder()
            },


            closeDepartureDateInPlace() {
                 this.departureInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveDepartureDateInPlace() {
                this.order.departureflag = true
                this.order.datedeparture = {date: null} //Добавляем свойств, чтобы в следующей строке не было ошибки обращения к несуществующему свойству
                this.order.datedeparture.date = this.editedItem.datedeparture
                this.order.timedeparture = this.editedItem.timeDepartureSelect.value
                this.closeDepartureDateInPlace()
                this.updateOrder()
            },
            editDatedeparture() {
                this.departureInPlace = true
                this.editedItem.timeDepartureSelect = null
                this.editedItem.datedeparture = null
                this.$nextTick(() => {
                    this.$refs.datedeparture.focus();
                })
            },
            clearDatedeparture() {
                this.order.datedeparture.date = null
                this.order.timedeparture = null
                this.order.departureflag = null
                this.updateOrder()
            },


            editSalaryDate() {
                this.salaryInPlace = true
                this.editedItem.salarydate = null
                this.$nextTick(() => {
                    this.$refs.salarydate.focus();
                })
            },
            saveSalaryDateInPlace() {
                this.order.ordersalaryflag = true
                this.order.ordersalarydate = {date: null} //Добавляем свойств, чтобы в следующей строке не было ошибки обращения к несуществующему свойству
                this.order.ordersalarydate.date = this.editedItem.salarydate
                this.closeSalaryDateInPlace()
                this.updateOrder()
            },
            closeSalaryDateInPlace() {
                this.salaryInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },




            editOrderdeadline() {
                this.editedItem.orderdeadline = this.order.orderdeadline.date
                this.orderdeadlineInPlace = true
                this.$nextTick(() => {
                    this.$refs.orderdeadline.focus();
                })
            },
            closeOrderdeadline() {
                this.orderdeadlineInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveOrderdeadline() {
                this.order.orderdeadline.date = this.editedItem.orderdeadline
                this.closeOrderdeadline()
                this.updateOrder()
            },

            editOrderorganization() {
                CompanyDataService.get()
                    .then(response => {
                        this.orderorganizationItems = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                this.editedItem = this.order.orderorganization
                this.orderorganizationInPlace = true
            },

            closeOrderorganization() {
                this.orderorganizationInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },


            saveOrderorganization() {
                this.order.orderorganization = this.editedItem
                this.closeOrderorganization()
                this.updateOrder()
            },

            editAllowDownload() {
                this.editedItem = this.lkAllowDownload.find(item => item.value == this.order.allow_download_when_paying)
                this.allowDownloadInPlace = true
            },

            saveAllowDownload() {
                this.order.allow_download_when_paying = this.editedItem.value
                this.closeAllowDownload()
                this.updateOrder()
            },

            closeAllowDownload() {
                this.allowDownloadInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },


            formatOrderDate: function (orderdate) {
                if (orderdate !== null) {
                    return [orderdate.slice(8, 10), orderdate.slice(5, 7), orderdate.slice(0, 4)].join('.')
                }
            },
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            formatTime: function (time) {
                return time.slice(10,16)
            },
            openProject() {
                fileserver.get(`http://localhost:5000?name=${this.order.customerkod.fullname}&realestate=${this.order.estatecod.fulladdress}&ordernum=${this.order.contractnumber}&orderdate=${this.formatOrderDate(this.order.orderdata.date)}&projectfolder=${process.env.VUE_APP_PROJECTS_FOLDER}\\${this.order.orderid}`)
            },
            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                    .catch(error => {
                        console.log(error);
                    })
            },
            //Добавляем договор в избранное
            addToFavorite() {
                OrderDataService.addContractToFavorite(this.order.orderid, this.$store.state.auth.user.userid)
                    .then(response => {
                        this.order.favoriteforemployer = this.$store.state.auth.user.userid
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Удаляем договор в избранного
            deleteFromFavorite() {
                OrderDataService.deleteContractFromFavorite(this.order.orderid, this.$store.state.auth.user.userid)
                    .then(response => {
                        this.order.favoriteforemployer = "null"
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Изменение видимости блока "УЧАСТНИКИ"
            changeMemberShowBlock() {
                this.showMemberBlock = !this.showMemberBlock
                localStorage.setItem('showMemberBlock', JSON.stringify(this.showMemberBlock))
            }

        },

        mounted() {
            if (localStorage.showMemberBlock) {
                this.showMemberBlock = JSON.parse(localStorage.showMemberBlock)
            }

            this.getTaskInWorking() //Количество задач в работе

            //Запрос данных договора
            this.getOrder(this.$route.params.id); //Код текущего договора

        },
        destroyed() {
            //Обнуляем экономические показатели по договору в Vuex
            this.$store.dispatch('setOrderSum', 0)
            this.$store.dispatch('setExpensesSum', 0)
            this.$store.dispatch('setWageSum', 0)
        },

        //При переходе к этому же компоненту (когда меняем параметр маршута из окна горящих договоров)https://router.vuejs.org/ru/guide/essentials/dynamic-matching.html#%D0%BE%D1%82%D1%81%D0%BB%D0%B5%D0%B6%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D0%BE%D0%B2
        beforeRouteUpdate(to, from, next) {
                this.getOrder(to.params.id); //Код текущего договора
                next()
        },

        watch: {
            searchCustomer() {
                if (this.searchCustomer.length > 3) {
                    CustomerDataService.findBySurname(this.searchCustomer)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            searchEstate() {
                if (this.searchEstate.length > 5) {
                    RealestateDataService.find(this.searchEstate)
                        .then(response => {
                            this.estateItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        computed: {
            //ПОдсчет количиства дней по договору
            //https://coderoad.ru/7763327/%D0%9A%D0%B0%D0%BA-%D1%80%D0%B0%D1%81%D1%81%D1%87%D0%B8%D1%82%D0%B0%D1%82%D1%8C-%D1%80%D0%B0%D0%B7%D0%BD%D0%B8%D1%86%D1%83-%D0%B4%D0%B0%D1%82-%D0%B2-JavaScript
            deadlineDaysCalc() {
                let d1 = new Date(this.order.orderdata.date) //Дата договора
                let d2= new Date(this.order.orderdeadline.date) //Срок договора
                return parseInt((d2-d1)/(24*3600*1000));
            },
            getAllowDownload() {
                if (this.order.allow_download_when_paying == '0') return 'Без оплаты'
                if (this.order.allow_download_when_paying == '30') return 'Оплата 30% и более'
                if (this.order.allow_download_when_paying == '50') return 'Оплата 50% и более'
                if (this.order.allow_download_when_paying == '100') return 'Оплата 100%'
                else return null
            },


            //Сумма по договору (по смете)
            getOrderSum() {
                return this.$store.state.economic.ordersum;
            },
            //Сумма расходов
            getExpensesSum() {
                return this.$store.state.economic.expensessum;
            },
            //Сумма зарплаты
            getWageSum() {
                return this.$store.state.economic.wagesum;
            },
            //Прибыль
            getProfit() {
                return this.$store.getters.profit
            },
            //Прибыль
            getProfitability() {
                return Math.round((this.getProfit / this.getOrderSum) * 100)
            }
        },


    }

</script>
<style scoped>
    .user-message-date {
        font-size: 11px;
        color: #555;
        padding-left: 7px;
        font-weight: normal;
    }

    .user-message-post {
        font-size: 9px;
        margin-bottom: 10px;
    }

    .isNew {
        background: #44ddbf !important;
        color: white !important;
    }

    .isWork {
        background: #1E88E5 !important;
        color: white !important;
    }

    .isCompleted{
        background: #43A047 !important;
        color: white !important;
    }

    .isRefuse {
        background: #E53935 !important;
        color: white !important;
    }

    /*Убираем фон вкладки*/
    .theme--light.v-tabs-items {
        background-color: rgba(0, 0, 0, 0);
    }

    .numbers {
        font-size: 2rem;
        color: #242424;
        font-weight: 500;
    }
</style>