<!--Таблица Актов сдачи работ (Все акты)-->
<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Акты сдачи работ</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-form
                                v-model="valid"
                                lazy-validation
                        >
                            <v-text-field
                                    label="Номер акта"
                                    placeholder="Введите данные для поиска"
                                    v-model="actNumberString"
                                    @keyup.enter="get"
                            ></v-text-field>
                            <v-text-field
                                    label="Номер договора"
                                    placeholder="Введите данные для поиска"
                                    v-model="contractNumberString"
                                    @keyup.enter="get"
                            ></v-text-field>
                            <v-row>
                                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                    <v-text-field
                                            label="Дата акта"
                                            placeholder="Начало"
                                            v-model="startActDate"
                                            v-mask="'##.##.####'"
                                            :rules="externalRules.dateRules"
                                            ref="startDate"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                    <v-text-field
                                            placeholder="Окончание"
                                            v-model="endActDate"
                                            v-mask="'##.##.####'"
                                            :rules="externalRules.dateRules"
                                            ref="endDate"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col style="padding-top: 0; padding-bottom: 0">
                                    <v-autocomplete
                                            label="Руководитель проекта"
                                            v-model="managerid"
                                            :items="employeesItems"
                                            item-value="employeesid"
                                            item-text="fullname"
                                            clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col style="padding-top: 0; padding-bottom: 15px">
                                    <div class="data-label">Статус</div>
                                    <div v-for="item in status" :key="item.val">
                                        <v-checkbox
                                                v-model="statusArr"
                                                :value="item.val"
                                                :label="`${item.text}`"
                                                hide-details="true"
                                        ></v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row
                                    align="center"
                                    justify="space-around"
                                    class="mt-5"
                            >
                                <v-btn
                                        depressed
                                        @click="resetForm"
                                >
                                    Сбросить
                                </v-btn>
                                <v-btn
                                        :disabled="!valid"
                                        depressed
                                        color="primary"
                                        @click="get"
                                >
                                    Применить
                                </v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >
                    <template v-slot:item.date.date="{ item }">
                        {{ item.actdate.date | formatDocDate }}
                    </template>
                    <template v-slot:item.ordernumber="{ item }">
                        <router-link class="itemlink"   :to="{ name: 'EditContract', params: { id: item.orderid }}">
                            {{ item.ordernumber }}
                        </router-link>
                    </template>
                    <template v-slot:item.sum="{ item }">
                        {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.sum) }}
                    </template>
                    <template v-slot:item.customerfullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerid }}">
                            {{ item.customerfullname }}
                        </router-link>
                    </template>
                    <template v-slot:item.estatefulladdress="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditRealestates', params: { id: item.estateid }}">
                            {{ item.estatefulladdress }}
                        </router-link>
                    </template>
                    <template v-slot:item.isregistered="{ item }">
                        <v-icon
                                size="20"
                                color="green darken-2"
                                v-if="item.isregistered"
                                title="Документ зарегистрирован"
                        >
                            mdi-file-document-check-outline
                        </v-icon>
                        <v-icon
                                size="20"
                                color="red"
                                v-if="!item.isregistered"
                                title="Документ не зарегистрирован"
                        >
                            mdi-file-document-alert-outline
                        </v-icon>
                    </template>
                    <template slot="body.append"
                    >
                        <tr v-show="$can('ECONOMY_READ', 'all')">
                            <th></th>
                            <th>Всего:</th>
                            <th></th>
                            <th></th>
                            <th style="text-align: left">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getActsSumm())}}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </template>
<!--                    <template v-slot:item.tablecrossingserviceviewact="{ item }">-->
<!--                        <div >-->
<!--                            {{ getActSummByActivities(item.tablecrossingserviceviewact) }}-->
<!--                        </div>-->
<!--                    </template>-->
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import * as ExternalRules from "@/rules/ExternalRules";
    import ActDataService from "@/services/ActDataService";
    import ActivitiesDataService from "@/services/ActivitiesDataService";
    import EmployeeDataService from "@/services/EmployeeDataService";

    export default {
        name: "Acts",
        data () {
            return {
                loading: true,
                startActDate: '',
                endActDate: '',
                externalRules: ExternalRules,

                employeesItems: [], //Сотрудники
                managerid: null, //Выбранный сотрудник в фильтре (руководитель проекта)


                actNumberString: '', //Строка поиска - номер акта
                contractNumberString: '', //Строка поиска - номер договора
                status: [
                    { text: 'Зарегистрирован', val: '1' },
                    { text: 'Не зарегистрирован', val: '0' },
                ],
                statusArr: [], //Массив поиска - статусы
                maxResults: 500,

                headers: [
                    {
                        value: 'isregistered',
                        width: 10
                    },
                    {
                        text: 'Номер',
                        value: 'actnumber',
                        width: 100
                    },
                    {
                        text: 'Дата',
                        value: 'date.date',
                        width: 130
                    },
                    {
                        text: 'Договор',
                        value: 'ordernumber',
                        width: 100
                    },
                    {
                        text: 'Сумма',
                        value: 'sum',
                        width: 120
                    },
                    // {
                    //     text: 'Сумма с разбивкой по видам деятельности',
                    //     value: 'tablecrossingserviceviewact',
                    //     width: 100
                    // },
                    {
                        text: 'Клиент',
                        value: 'customerfullname',
                    },
                    {
                        text: 'Адрес',
                        value: 'estatefulladdress',
                    }
                ],
                items: [],
                activitiesItem: [],
                valid: true,
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.acts_actNumberString) {
                this.actNumberString = localStorage.acts_actNumberString
            }
            if (localStorage.acts_contractNumberString) {
                this.contractNumberString = localStorage.acts_contractNumberString
            }
            if (localStorage.acts_startActDate) {
                this.startActDate = localStorage.acts_startActDate
            }
            if (localStorage.acts_endActDate) {
                this.endActDate = localStorage.acts_endActDate
            }
            if (localStorage.acts_managerid) {
                this.managerid = JSON.parse(localStorage.getItem('acts_managerid'))
            }
            if (localStorage.acts_status) {
                this.statusArr = JSON.parse(localStorage.getItem('acts_status'))
            }

            //Запрос видов деятельности
            this.getActivities()

            this.getEmployees() //Запрос всех сотрудников для выбора в списке поиска руководителя проекта

            //Запрашиваем акты
            this.get()


        },
        filters: {
            formatDocDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
        },
        methods: {
            resetForm: function () {
                this.actNumberString = ''
                this.contractNumberString = ''
                this.startActDate = ''
                this.endActDate = ''
                this.statusArr = []
                this.managerid = null
            },
            get: function () {
                //Save Localstorage
                localStorage.acts_actNumberString = this.actNumberString
                localStorage.acts_contractNumberString = this.contractNumberString
                localStorage.acts_startActDate = this.startActDate
                localStorage.acts_endActDate = this.endActDate
                localStorage.setItem('acts_managerid', JSON.stringify(this.managerid))
                localStorage.setItem('acts_status', JSON.stringify(this.statusArr))

                this.loading = true

                let params = {
                    actnumber: this.actNumberString,
                    ordernumber: this.contractNumberString,
                    startdate: this.startActDate,
                    enddate: this.endActDate,
                    is_registered: this.statusArr,
                    managerid: this.managerid, //Руководитель проекта
                    max_results: this.maxResults,
                }

                ActDataService.findItems(params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Выборка всех Направлений деятельности
            getActivities() {
                ActivitiesDataService.find()
                    .then( response => {
                        this.activitiesItem = response.data
                    })
                    .catch(error => console.log(error));
            },

            //Сумма актов в футере
            getActsSumm (){
                let Summ = 0
                this.items.forEach(function (item) {
                        Summ = Summ + Number(item.sum)
                })
                return Summ
            },


            //Сумма актов в разрезе видов деятельности
            getActSummByActivities(array) {
                let rez = {};
                array.map(obj=>{
                    rez[obj.serviceviewkod.activiti] ? rez[obj.serviceviewkod.activiti] += Number(obj.serviceprice) : rez[obj.serviceviewkod.activiti] = Number(obj.serviceprice)
                })
                return rez
            },

            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },

            //Сумма в футере
            getDocumentsSumm() {
                let Summ = 0
                this.items.forEach(function (item) {
                    Summ  = Summ + Number(item.sum)
                })
                return Summ
            },

            //Запрос всех сотрудников для выбора в списке
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },


        },


    }
</script>

<style scoped>

    /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0;
        padding-top: 4px;
    }

</style>