<template>
  <div>
      <v-row>
          <v-col cols="2">
              <v-card flat>
                  <v-card-text>
                      <v-row justify="center">
                          <v-date-picker
                                  v-model="dates"
                                  locale="ru"
                                  full-width
                                  range
                                  first-day-of-week="1"
                                  no-title
                          ></v-date-picker>
                      </v-row>
                      <v-row
                              align="center"
                              justify="space-around"
                      >
                          <v-btn
                                  depressed
                                  @click="resetForm"
                                  class="mb-3"
                          >
                              Сбросить
                          </v-btn>
                          <v-btn
                                  depressed
                                  color="primary"
                                  @click="fillAllCalendar"
                                  class="mb-3"
                          >
                              Применить
                          </v-btn>
                      </v-row>
                  </v-card-text>
              </v-card>

          </v-col>
          <v-col cols="10">
              <v-calendar
                        type="custom-daily"
                      weekdays="1, 2, 3, 4, 5, 6, 0"
                      :events="events"
                      :event-color="getEventColor"
                      locale="ru"
                      color="primary"
                      :start="startDate"
                      :end="endDate"

                        first-interval="7"
                        interval-count="13"
                        interval-height="80"
                        :short-intervals="shortintervals"
                        :interval-style="intervalStyle"
                        :event-overlap-mode="stack"
                        :event-overlap-threshold="30"
                        @click:event="showEvent"
              >
<!--                  //Делаем через слот, чтобы не показывать время события-->
                  <template v-slot:event="{ event }">
                      <div
                              style="white-space: pre-line; padding-left: 2px;"
                              v-if="event.type == 'departure'"
                      >
                          <v-icon
                                  size="16"
                                  color="white"
                          >
                              mdi-car-outline
                          </v-icon>
                          <span>
                              {{ event.name }}
                          </span>
                      </div>

                      <div
                              style="white-space: pre-line; padding-left: 2px;"
                              v-if="event.type == 'task'"
                      >
                          <v-icon
                                  size="16"
                                  color="white"
                          >
                              mdi-check-circle-outline
                          </v-icon>
                          <span :class="{taskcompleted : event.status}">
                              {{ event.name }}
                          </span>
                      </div>

                      <div
                              style="white-space: pre-line; padding-left: 2px;"
                              v-if="event.type == 'birthday'"
                      >
                          <v-icon
                                  size="16"
                                  color="white"
                          >
                              mdi-cake-variant-outline
                          </v-icon>
                          <span>
                              {{ event.name }}
                          </span>
                      </div>
                  </template>
              </v-calendar>
              <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
              >
                  <v-card
                          color="grey lighten-4"
                          width="400px"
                          flat
                  >
                      <v-toolbar
                              :color="selectedEvent.color"
                              dense
                              flat
                                style="color: white"
                      >
<!--                          <v-btn icon>-->
<!--                              <v-icon>mdi-pencil</v-icon>-->
<!--                          </v-btn>-->
                          <v-toolbar-title v-if="selectedEvent.type == 'departure'">Выезд</v-toolbar-title>
                          <v-toolbar-title v-if="selectedEvent.type == 'task'">Задача</v-toolbar-title>
                          <v-toolbar-title v-if="selectedEvent.type == 'birthday'">День рождения</v-toolbar-title>

                          <v-spacer></v-spacer>
<!--                          Добавить сюда потом полезыне кнопки-->
                          <v-btn
                                  icon
                                  @click="selectedOpen = false"
                                  color="white"
                          >
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
<!--                          <v-btn icon>-->
<!--                              <v-icon>mdi-dots-vertical</v-icon>-->
<!--                          </v-btn>-->
                      </v-toolbar>
                      <v-card-text>
                          <span v-html="selectedEvent.name"></span>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn
                              text
                              color="secondary"
                              v-if="selectedEvent.type == 'departure'"
                              @click="goToOrder(selectedEvent.orderid)"
                          >
                              Подробнее
                          </v-btn>
                          <v-btn
                                  text
                                  color="secondary"
                                  v-if="selectedEvent.type == 'task'"
                                  @click="goToTask(selectedEvent.taskid)"
                          >
                              Подробнее
                          </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-menu>
          </v-col>
      </v-row>
  </div>
</template>

<script>
    import CalendarDataService from "@/services/CalendarDataService";
    import TaskDataService from "@/services/TaskDataService";
    import EmployeeDataService from "@/services/EmployeeDataService";

    //Это раскрашивает выходные.
    //Фиг понял как это работает
    //https://codepen.io/sneaky666/pen/JgvGEB?editors=1010
    const stylings = {
        workday (interval) {
            const inactive = interval.weekday === 0 ||
                interval.weekday === 6
            const startOfHour = interval.minute === 0
            const dark = this.dark
            const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

            return {
                backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(255,0,0,0.06)') : undefined,
                borderTop: startOfHour ? undefined : '1px dashed ' + mid,
            }
        }
    }

export default {
  name: 'Calendar',
  components: {
    // CustomersTable
  },

    data: function() {
        return {
            dates: [new Date().toISOString().substr(0, 10), this.addDays(6).toISOString().substr(0, 10)],
            startDate: new Date().toISOString().substr(0, 10),
            endDate: this.addDays(6).toISOString().substr(0, 10),
            departure: [],
            events: [],
            tasks: [], //Задачи
            birthday: [], //Дни рождения
            currDate: new Date(), //Текущая дата
            shortintervals: false,


            selectedOpen: false,
            selectedEvent: {},
            selectedElement: null,
        }
    },
    mounted() {
        //Запрашиваем данные с сервера
        this.getDeparture()
        this.getTasks()
        this.getBirthday()
    },
    computed: {
        intervalStyle () {
            return stylings['workday'].bind(this)
        },
    },
    methods: {
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

      fillAllCalendar() {
          this.getDeparture()
          this.getTasks()
          this.getBirthday()
      },

        getEventColor(event) {
            return event.color
        },

        addDays(days) {
            let result = new Date();
            result.setDate(result.getDate() + days);
            return result;
        },

        //Запрос выездов
        getDeparture() {
            this.departure = []
            this.events = []

            if (this.dates.length === 1) {
                this.startDate = this.dates[0]
                this.endDate = this.dates[0]
            }
            if (this.dates.length === 2) {
                let arrDates=[];
                arrDates.push(new Date(this.dates[0]))
                arrDates.push(new Date(this.dates[1]))

                let maxDate=new Date(Math.max.apply(null, arrDates));
                let minDate=new Date(Math.min.apply(null, arrDates));

                this.startDate = minDate.toISOString().substr(0, 10)
                this.endDate = maxDate.toISOString().substr(0, 10)
            }

            CalendarDataService.getDeparture(this.startDate , this.endDate)
                .then(response => {
                    this.departure= response.data
                    this.fillCalendar(this.departure.length)
                })
                .catch(e => {
                    console.log(e)
                })
        },

        //Запрос задач
        getTasks() {
            let params = {
                startTaskDeadlineDate: this.startDate,
                endTaskDeadlineDate: this.endDate
            }
            //Возвращаем все задачи - выполненные и нет. Тестовый. Потом изменить тольоко выполненные или по дате выборку делать, чтобы не загружать трафик
            TaskDataService.find(this.$store.state.auth.user.userid, params)
                .then(response => {
                    this.tasks = response.data
                    this.fillTaskCalendar(this.tasks.length)
                })
                .catch(e => {
                    console.log(e)
                })
        },

        //Загружаем ДР
        getBirthday() {
            EmployeeDataService.getbirthdayPeriod(this.startDate, this.endDate)
                .then(response => {
                    this.birthday = response.data
                    this.fillBirthdayCalendar(this.birthday.length)
                })
                .catch(e => {
                    console.log(e)
                })
        },

        fillBirthdayCalendar(length) {
            let index;
            for (index = 0; index < length; ++index) {
                let birthdayDate = new Date(this.birthday[index].birthday.date.split(' ')[0])// Преобразуем строку в объект Date
                let currentYear = new Date().getFullYear()// Получаем текущий год
                birthdayDate.setFullYear(currentYear)// Устанавливаем новый год

                let updatedDateString = birthdayDate.toISOString().split('T')[0]// Преобразуем объект Date обратно в строку формата YYYY-MM-DD


                this.events.push({
                    name:  this.birthday[index].fullname,
                    start: updatedDateString,
                    color: 'pink',
                    timed: 1,
                    type: 'birthday'
                })
            }
        },

        fillTaskCalendar(length) {
            let index;
            for (index = 0; index < length; ++index) {
                let TaskEndDate = this.tasks[index].end_date.date.split(' ')[0] //Дедлайн задачи - дата - строка
                let TaskEndTime = this.tasks[index].end_time //Дедлайн задачи - время

                // let startDate = TaskEndDate
                // let endDate = TaskEndDate

                //Если есть время выполнения задачи (не null)
                if (TaskEndTime !== null) {
                     // startDate = TaskEndDate.setHours(TaskEndDate.getHours() + TaskEndTime.split(':')[0])
                     // endDate = startDate + 7200000 //Прибавляем 2 часа (миллисекунды)
                    TaskEndDate = TaskEndDate + ' ' + TaskEndTime
                }

                this.events.push({
                     name:  this.tasks[index].name,
                     start: TaskEndDate,
                     // end: endDate,
                     color: 'blue',
                     timed: 1,
                     type: 'task',
                     taskid: this.tasks[index].id,
                     status: this.tasks[index].status //Статус задачи - выполнена/невыполнена
                })
            }

            // this.events.push({
            //     name: 'Выезд на объект10',
            //     start: '2025-02-28 09:00',
            //     end: '2025-02-28 09:00',
            //     color: 'pink',
            //
            // })
            // this.events.push({
            //     name: 'Выезд на объект 2 ывафыв укафывеп цука',
            //     start: '2025-02-28 09:00',
            //     end: '2025-02-28 09:00',
            //     color: 'pink',
            //
            // })
            // this.events.push({
            //     name: 'День рождения',
            //     start: '2025-02-28',
            //     // end: '2025-02-28',
            //     color: 'pink',
            //
            // })
        },

        fillCalendar(length){
            let index;
            for (index = 0; index < length; ++index) {
                let dateDeparture = new Date(this.departure[index].datedeparture.date) //Дата выезда
                let timeDeparture = this.departure[index].timedeparture.split(':')[0] //Время выезда (часы)

                let startDateDeparture = dateDeparture.setHours(dateDeparture.getHours() + timeDeparture)
                let endDateDeparture = startDateDeparture + 7200000 //Прибавляем 2 часа (миллисекунды)

                this.events.push({
                    name: this.departure[index].estatecod.fulladdress,
                    start: startDateDeparture,
                    end: endDateDeparture,
                    color: 'green',
                    timed: 1,
                    type: 'departure',
                    orderid: this.departure[index].orderid,
                    timeDeparture: timeDeparture
                })
            }
        },
        resetForm() {
            this.dates = [new Date().toISOString().substr(0, 10), this.addDays(6).toISOString().substr(0, 10)]
            this.getDeparture()  // Если хотим сразу обновить календарь
        },

        goToOrder(orderid) {
            this.$router.push({name: 'EditContract', params: { id: orderid }})
        },

        goToTask(taskid) {
            this.$router.push({name: 'EditTask', params: { id: taskid }})
        }

    }
}
</script>

<style>
    .v-application .pl-1  {
        white-space: pre-line;
        font-weight: lighter;
    }

    .taskcompleted {
        text-decoration: line-through;
        color: #3c3c3c
    }
</style>

