<template>
    <div>

        <div class="text-center">
            <v-progress-circular
                    indeterminate
                    color="gray"
                    v-show="loadingTable"
            ></v-progress-circular>
        </div>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
<!--                    <th class="text-left"  width="50px">-->
<!--                        Код-->
<!--                    </th>-->
                    <th class="text-left">
                        Номенклатура
                    </th>
                    <th class="text-left">
                        Ед.изм.
                    </th>
                    <th class="text-left" >
                        Кол.
                    </th>
                    <th class="text-left">
                        Цена
                    </th>
                    <th class="text-left">
                        Сумма
                    </th>
<!--                    <th class="text-left" style="padding: 3px;">-->
<!--&lt;!&ndash;                        Сдвинуть вверх&ndash;&gt;-->
<!--                    </th>-->
<!--                    <th class="text-left" style="padding: 3px;">-->
<!--&lt;!&ndash;                        Сдвинуть вниз&ndash;&gt;-->
<!--                    </th>-->
                    <th class="text-left">
<!--                        Удалить-->
                    </th>
                </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="2" class="pt-5">Итого: <span style="background-color:  #e6e6e6; padding: 5px 5px;border-radius: 4px;margin-left: 10px;color: black; font-weight: 500">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getNomenclatureSumm()) }}</span></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
                <tbody>
                <tr
                        v-for="(item, index) in nomenclature"
                        :key="item.servicekod.serviceid"
                >
                    <td>{{ item.servicekod.servicename}} <span v-if="item.servicekod.activiti" style="color: #1f98ad">[{{item.servicekod.activiti}}]</span></td>
                    <td>{{ item.servicekod.servicemeasurekod.measureshortname }}</td>
                    <td>
                        <!--Количество-->
                        <span
                                class="editvalue"
                                @click="editCountValue(index)"
                                v-show="!item.isCountEditing"
                        >
                            {{ item.servicecount }}
                        </span>
                        <input
                                type="text"
                                style="width: 20px;"
                                class="inputinfocus"
                                @keypress="isLetterOrNumber($event)"
                                @keypress.enter="$event.target.blur()"
                                v-show="item.isCountEditing"
                                v-model="editedItem.servicecount"
                                ref="count_edit_input"
                                @blur="saveCountValue(item)"
                        >
<!--                        @focus="$event.target.select()"-->
                    </td>
                    <td>
                        <!--Стоимость-->
                        <span class="editvalue"
                              @click="editPriceValue(index);"
                              v-show="!item.isPriceEditing"
                        >{{ item.serviceprice }}</span>
                        <input
                                type="text"
                                style="width: 50px;"
                                class="inputinfocus"
                                @keypress="onlyForCurrency"
                                @keypress.enter="$event.target.blur()"
                                v-show="item.isPriceEditing"
                                v-model="editedItem.serviceprice"
                                ref="price_edit_input"
                                @blur="savePriceValue(item)"
                        >
                    </td>
                    <td>{{ (item.serviceprice * item.servicecount).toFixed(2) }}</td>

<!--                    <td style="padding: 3px;">-->
<!--                        <v-icon-->

<!--                        >-->
<!--                            mdi-arrow-up-->
<!--                        </v-icon>-->
<!--                    </td>-->
<!--                    <td style="padding: 3px;">-->
<!--                        <v-icon-->

<!--                        >-->
<!--                            mdi-arrow-down-->
<!--                        </v-icon>-->
<!--                    </td>-->

                    <td>
                        <v-icon
                                title="Удалить"
                                @click="deleteItem(item.servicekod.serviceid)"
                                size="20"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-autocomplete
            label="Добавить услугу или материал"
            v-model="serviseobj"
            :items="serviseViewItems"
            item-value="serviceid"
            item-text="servicename"
            @change="addServiceToNomenclature"
            clearable
            return-object
        >
            <template v-slot:item="data">
<!--                <v-list-item-content v-text="data.item.servicename"></v-list-item-content>-->
                <v-list-item-content>
                    <v-list-item-title >{{ data.item.servicename }} <span style="color: #1f98ad;" v-if="data.item.activiti">[{{data.item.activiti}}]</span></v-list-item-title>
<!--                    Проверяем на существование свойств. Необходимо для геореестра когда нет групп и направлений деятельности-->
                    <v-list-item-subtitle v-if="data.item.servicegroupkod" v-html="data.item.servicegroupkod.servicegroupname"></v-list-item-subtitle>
                </v-list-item-content>
            </template>

        </v-autocomplete>

        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="mt-3 mb-3"
                        v-if="VUE_APP_TENANT2 !== 'GEOREESTR'"
                >
                    <v-icon
                            left
                    >
                        mdi-printer-outline
                    </v-icon>
                    Документ
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        @click="printContract(item)"
                >
                    <v-list-item-title>
                        <v-icon>
                            mdi-file-document-outline
                        </v-icon>
                        {{ item}}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>

</template>


<script>
    import NomenclatureDataService from "@/services/NomenclatureDataService";
    import ServiceViewDataService from "@/services/ServiceViewDataService";
    import OrderDataService from "@/services/OrderDataService";


    export default {
        name: "NomenclatureTable",
        props: ['company'],
        data () {
            return {
                nomenclature: null,
                editedItem: {}, //В этой переменной сохраняем объект редактирования
                serviseViewItems: [], //виды услуг (дял автокомплетера)
                serviseViewSearch: null,
                serviseobj: null,
                price:null,
                items: this.company.contractlist.split('~'),
                loadingTable: true,
                VUE_APP_TENANT2: process.env.VUE_APP_TENANT
            }
        },
        methods: {
            onlyForCurrency ($event) {
                // console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                // only allow number and one dot
                if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
                    $event.preventDefault();
                }

                // restrict to 2 decimal places
                if(this.price!=null && this.price.indexOf(".")>-1 && (this.price.split('.')[1].length > 1)){
                    $event.preventDefault();
                }
            },
            isLetterOrNumber(e) {
                let char = String.fromCharCode(e.keyCode);
                if (/^(?:\d+(?:\.\d+)?)?$/.test(char)) return true;
                else e.preventDefault();
            },
            isDecimal(evt) {
                // evt = (evt) ? evt : window.event;
                // let charCode = (evt.which) ? evt.which : evt.keyCode;
                // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                //     evt.preventDefault();
                // } else {
                //     return true;
                // }
                if (!/\d/.test(event.key) && event.key !== '.') return evt.preventDefault();
            },
            getNomenclature(orderid) {
                NomenclatureDataService.find(orderid)
                    .then( response => {
                        this.nomenclature = response.data
                        //Добавляем в массив оьбъектов свойства, необходимые для реализации редактирования значений таблицы сметы на лету
                        let index;
                        for (index = 0; index < this.nomenclature.length; ++index) {
                        this.$set(this.nomenclature[index],'isCountEditing',false) //Количество
                            this.$set(this.nomenclature[index],'isPriceEditing',false) //Цена
                        }
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },
            getServiceView() {
                ServiceViewDataService.find()
                    .then(response => {
                        this.serviseViewItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Редактирование количества
            editCountValue(index) {
                this.editedItem.servicecount = this.nomenclature[index].servicecount
                this.nomenclature[index].isCountEditing = true
                this.$nextTick(() => {
                // this.$refs.itemedit[index].focus();
                this.$refs.count_edit_input[index].select();
                })
            },
            //Редактирование цены
            editPriceValue (index) {
                this.editedItem.serviceprice = this.nomenclature[index].serviceprice
                this.nomenclature[index].isPriceEditing = true
                this.$nextTick(() => {
                    this.$refs.price_edit_input[index].select();
                })
            },
            saveCountValue(item) {
                if (this.editedItem.servicecount < 1) {this.editedItem.servicecount = 1} //Если количество меньше 1 , присваеваем 1

                item.servicecount  = this.editedItem.servicecount
                item.isCountEditing = false

                this.editedItem = {}

                NomenclatureDataService.update(this.$route.params.id, item.servicekod.serviceid, item.servicecount, item.serviceprice)
                    .then( response => {
                        console.log(response)

                        this.getNomenclature(this.$route.params.id); //Код текущего договора
                    })
                    .catch(error => console.log(error));
            },
            savePriceValue(item) {
                if (this.editedItem.serviceprice === '') {this.editedItem.servicecount = 0.00} //Если цена пустая, присваиваем 0

                item.serviceprice  = this.editedItem.serviceprice
                item.isPriceEditing = false

                this.editedItem = {}

                NomenclatureDataService.update(this.$route.params.id, item.servicekod.serviceid, item.servicecount, item.serviceprice)
                    .then( response => {
                        console.log(response)
                        this.getNomenclature(this.$route.params.id); //Код текущего договора
                    })
                    .catch(error => console.log(error));
            },
            getNomenclatureSumm() {
                let summ = 0
                this.nomenclature.forEach( function (item) {
                   summ = summ + Number(item.serviceprice * item.servicecount)
               })
                this.$store.dispatch('setOrderSum', summ ) //Сохраняем сумму договора в хранилище (для экономики)
                return summ
            },
            addServiceToNomenclature() {
                NomenclatureDataService.create(this.$route.params.id, this.serviseobj.serviceid,  this.serviseobj.price)
                    .then( response => {
                        console.log(response)
                        this.$nextTick(() => {
                            this.serviseobj = null;
                        })
                        this.getNomenclature(this.$route.params.id);
                    })
                    .catch(error => console.log(error));
            },
            deleteItem(serviceid) {
                NomenclatureDataService.delete(this.$route.params.id, serviceid)
                    .then( response => {
                        console.log(response)
                        this.getNomenclature(this.$route.params.id);
                    })
                    .catch(error => console.log(error));
            },

            printContract(contractname) {
                OrderDataService.printContract(this.company.companyid, contractname, this.$route.params.id)
                    .then( response => {
                        let headers = response.headers;
                        let fileURL = window.URL.createObjectURL(new Blob([response.data],{ type : headers['content-type']}));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', contractname);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .catch(error => console.log(error));
            }
        },
        mounted () {
            this.getNomenclature(this.$route.params.id); //Код текущего договора
            this.getServiceView() //Запрос видов услуг
        },
        watch: {

        },
    }
</script>

<style scoped>
    .summ {
        color: #ff5a5a;
        font-weight: bold;
    }

    .inputinfocus {
        border:0;
        border-bottom: 2px solid #00b028;
        outline:none;
    }

    .editvalue {
        border-bottom: 1px dotted #3e3535;
    }

    .mdi-trash-can-outline:hover {
        color: #ff3d29;
    }
</style>