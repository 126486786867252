<!--Бухгалтерские счета на оплату-->
<template>
    <div>
        <v-card
                outlined
                class="mb-4"
        >
            <v-card-title>
                Счета на оплату
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click.stop="showNewAccountDialog = true"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
                <AddAccountForm
                        v-model="showNewAccountDialog"
                        @update-accounts-table="getAccounts($route.params.id)"
                        :order_id="$route.params.id"
                >
                </AddAccountForm>
            </v-card-title>
            <v-card-text>

        <div class="text-center">
            <v-progress-circular
                    indeterminate
                    color="gray"
                    v-show="loadingTable"
            ></v-progress-circular>
        </div>

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Номер
                    </th>
                    <th class="text-left">
                        Дата
                    </th>
                    <th class="text-left">
                        Срок оплаты
                    </th>
                    <th class="text-left" width="100px">
                        Статус
                    </th>
                    <th class="text-left" >
                        Сумма
                    </th>
<!--                    <th class="text-left" width="30px">-->
<!--                    &lt;!&ndash;Редактировать&ndash;&gt;-->
<!--                    </th>-->
                    <th class="text-left" width="30px">
                        <!--Печать-->
                    </th>
                    <th class="text-left" width="50px">
                        <!--Удалить-->
                    </th>
                </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="2">Итого: <span style="background-color:  #e6e6e6; padding: 5px 5px;border-radius: 4px;margin-left: 10px;color: black; font-weight: 500">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getAccountsSumm()) }}</span></td>

                        <td></td>
                        <td></td>
                        <td></td>
<!--                        <td></td>-->
                    </tr>
                </tfoot>
                <tbody>
                <tr
                        v-for="(item, index) in accounts"
                        :key="item.accountid"
                >
                    <td>
                        <router-link class="itemlink" :to="{ name: 'EditAccount', params: { id: item.accountid }}">
                            {{ item.accountnumber }}
                        </router-link>
                    </td>
                    <td>{{ item.accountdate.date | formatAccountDate}}</td>
                    <td>
                        <div :class="{enddeadline: getDeadlineStatus(item.deadline.date, item.status) === true}">
                            {{ item.deadline.date | formatAccountDate }}
                        </div>
                    </td>
                    <td> <AccountStepper :status="item.status" :accountid="item.accountid"/> </td>
                    <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.accountsum) }}</td>
<!--                    <td>-->
<!--                        <v-icon-->
<!--                                title="Редактировать"-->
<!--                                @click="editAccount(index)"-->
<!--                                size="20"-->
<!--                        >-->
<!--                            mdi-pencil-outline-->
<!--                        </v-icon>-->
<!--                    </td>-->
                    <td>
                        <v-menu
                                open-on-hover
                                offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-progress-circular
                                        indeterminate
                                        :width="2"
                                        :size="20"
                                        color="primary"
                                        v-show="item.showLoadingFileProgress"
                                ></v-progress-circular>
                                <v-icon
                                        title="Печать"
                                        size="20"
                                        v-show="!item.showLoadingFileProgress"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    mdi-printer-outline
                                </v-icon>
                            </template>
                            <v-list dense>
                                <v-list-item @click="printAccount(item.accountid, 'word', index)">
                                    <v-list-item-title>Word документ</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="printAccount(item.accountid, 'pdf', index)">
                                    <v-list-item-title>PDF документ</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                    <td>
                        <v-icon
                                title="Удалить"
                                @click="deleteAccount(item.accountid)"
                                size="20"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>


            </v-card-text>
        </v-card>


    </div>
</template>


<script>
    import AccountDataService from "@/services/AccountDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import AccountStepper from "@/components/elements/AccountStepper";
    import AddAccountForm from "@/components/AddAccountForm";

    export default {
        name: "AccountTable",
        props: ['company'],
        components: {AccountStepper, AddAccountForm},
        data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD 00:00:00.000000',
                },
                accounts: null,
                accountEditDialog: false,
                valid: true,
                accountNumber: null,
                accountDate: null,
                accountSum: null,
                externalRules: ExternalRules,
                loadingTable: true,
                showNewAccountDialog:false
            }
        },
        methods: {
            addAccount() {
                this.getNextAccountNumber()
                this.accountDate = new Date().toISOString().substr(0, 10) + ' 00:00:00.000000'
                this.accountEditDialog = true

            },
            getNextAccountNumber() {
                AccountDataService.getNextNumber()
                    .then(response => {
                        this.accountNumber = response.data
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            closeDialog() {
                this.accountEditDialog = false
                this.$nextTick(() => {
                    this.accountNumber = null
                    this.accountDate = null
                    this.accountSum = null
                    this.editedIndex = -1
                })
            },

            updateAccount(item) {
                AccountDataService.update(this.accounts[item].accountid, this.accounts[item])
                    .then(response => {
                        console.log(response.data)
                        this.getAccounts(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            getAccounts(orderid) {
                let params = {
                    orderid: orderid
                }
                AccountDataService.find(params)
                    .then( response => {
                        //this.accounts = response.data
                        //Добавляем в массив новое свойство: флаг скачивания файла с сервера (для отображения индикатора скачивания файла)
                        this.accounts = response.data.map(current => {
                            let item = Object.assign({}, current)
                            item.showLoadingFileProgress = false
                            return item
                        })
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },


            getAccountsSumm() {
                let summ = 0
                this.accounts.forEach( function (item) {
                   summ = summ + Number(item.accountsum)
               })
                return summ
            },


            deleteAccount(id) {
                AccountDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getAccounts(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            printAccount(accountid, type, index) {
                this.accounts[index].showLoadingFileProgress = true
                AccountDataService.printAccount(this.company.companyid, accountid, type)
                    .then( response => {
                        let headers = response.headers;
                        let fileURL = window.URL.createObjectURL(new Blob([response.data],{ type : headers['content-type']}));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Счет_' + this.accounts[index].contractnumber.replaceAll("/","_")); //Формируем имя файла для скачивания
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        this.accounts[index].showLoadingFileProgress = false
                    })
                    .catch(error => console.log(error));
            },

            //True - оплата счета просрочена
            getDeadlineStatus(date, status) {
                if (date)
                    if ((new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) && ( status === '0') ) {
                        return true
                    } else {
                        return false
                    }
            },
        },
        mounted () {
            this.getAccounts(this.$route.params.id); //Код текущего договора
        },
        watch: {
        },
        filters: {
            formatAccountDate: function (accountdate) {
                return [accountdate.slice(8,10),accountdate.slice(5,7),accountdate.slice(0,4)].join('.')
            },
        },
    }
</script>

<style scoped>

</style>